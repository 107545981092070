import FlipCountdown from "@rumess/react-flip-countdown";
import React from "react";
//import Countdown from "react-countdown";
/*
const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <>Happy Birthday Swami!</>;
    } else {
      // Render a countdown
      return (
        <ul className="countdown">
            <li>
                <big>{days}</big>
                <span>Days</span>
            </li>
            <li>
                <big>{hours}</big>
                <span>Hours</span>
            </li>
            <li>
                <big>{minutes}</big>
                <span>Minutes</span>
            </li>
            <li>
                <big>{seconds}</big>
                <span>Seconds</span>
            </li>
        </ul>
      );
    }
  };
*/
export default class CountDown extends React.Component{

    constructor(props){
        super(props);
        console.log(this.props.endAt);
    }

    render(){
        return <>
        <FlipCountdown
        dayTitle='Days'
        hourTitle='Hours'
        minuteTitle='Minutes'
        secondTitle='Seconds'
        hideYear
        hideMonth
        
        theme={'light'}
                size='small' // Options (Default: medium): large, medium, small, extra-small.
                endAt={this.props.endAt} // Date/Time
            />
        </>
    }
}