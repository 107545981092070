import './App.css';
import React, { useState, useEffect } from 'react';
import MapWithMarkers from './MapWithMarkers';
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, GoogleAuthProvider, signInWithPopup, OAuthProvider, getRedirectResult, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import CountDown from './CountDown';
import Birthday from './Birthday';

const firebaseConfig = {
  apiKey: "AIzaSyCacYdt29AwswA4fOjDgi9AbfF3d9L2ZRM",
  authDomain: "swami-birthday-99.firebaseapp.com",
  projectId: "swami-birthday-99",
  storageBucket: "swami-birthday-99.appspot.com",
  messagingSenderId: "738215135767",
  appId: "1:738215135767:web:6d9bb78522919b22dc6a52",
  measurementId: "G-CR9FKQMPK4",
  databaseURL: "https://swami-birthday-99-default-rtdb.asia-southeast1.firebasedatabase.app"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const rdb = getDatabase(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export default function App() {
  const [user, setUser] = useState(false);
  const [hideCurtain, setHideCurtain] = useState(false);

  const signOutUser = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.log(analytics);
      // Handle error
    }
  };

  const signIn = async (mode = '') => {
    let currentUser, provider;
    try {
      switch (mode) {
        case 'yahoo':
          provider = new OAuthProvider('yahoo.com');
          currentUser = await signInWithPopup(auth, provider);
          currentUser = currentUser.user;
          break;
        case 'microsoft':
          provider = new OAuthProvider('microsoft.com');
          currentUser = await signInWithPopup(auth, provider);
          currentUser = currentUser.user;
          break;
        case 'google':
          provider = new GoogleAuthProvider();
          provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
          currentUser = await signInWithPopup(auth, provider);
          currentUser = currentUser.user;
          break;
        case 'anonymous':
          currentUser = await signInAnonymously(auth);
          currentUser = currentUser.user;
          break;
        default:
          currentUser = auth.currentUser;
          break;
      }
      setUser(currentUser);
    } catch (ex) {
      console.log(ex.code, ex.message);
    }
  };

  const openCurtains = () => {
    const curtain = document.getElementById("curtain");
    curtain.className = "open";
    
    const scene = document.getElementById("scene");
    scene.className = "expand";
  };

  useEffect(() => {
    const initialize = async () => {
      openCurtains();
      await getRedirectResult(auth);
      await signIn();
    };
    initialize();
  }, []);

 

  return (
    <div className="App">
      {!hideCurtain &&
        <div id='scene'>
          <div id='curtain'>
            <div className='contents'>
              <div className="canvas-container">
                <Birthday />
              </div>
              <div className='bg-gold p-3 position-relative'>
                <h1 data-heading="Happy Birthday Swami ...">Happy Birthday Swami ...</h1>
                <div className='justify-content-center'>
                  <img src='/99-birthday.png' alt='' style={{ maxWidth: "360px" }} />
                </div>
                <button
                  onClick={() => setHideCurtain(true)}
                  className='btn btn-lg btn-primary'
                  style={{ maxWidth: "100%", cursor: "pointer", position: "relative", zIndex: 99 }}
                >
                  Click to Continue
                </button>
              </div>
            </div>
            <div className='ground'></div>
            <div className='left'></div>
            <div className='right'></div>
          </div>
        </div>
      }
      <header>
        <div className='logo-container'>
          <div className='logo-img d-none'>
            <img style={{ width: "45px" }} src="https://www.sssmediacentre.org/sssct.png" alt='' />
            <div className='logo-tooltip w-80'>
              <a href='https://www.srisathyasai.org' target='_blank' rel="noopener noreferrer">
                Sri Sathya Sai Central Trust
              </a>
            </div>
          </div>
          <div className='countdown-container' style={{ padding: "5px 10px" }}>
            <div className='countdown' style={{ padding: "0px 10px 10px 10px" }}>
              <CountDown endAt={'2024-11-23 00:00:00'} />
            </div>
          </div>
          <div className='logo-img d-none'>
            <img style={{ width: "45px" }} src="https://www.sssmediacentre.org/755f75139b00a808ae072efc6cdd7ae8.png" alt='' />
            <div className='logo-tooltip w-80'>
              <a href='https://www.sssmediacentre.org' target='_blank' rel="noopener noreferrer">
                Sri Sathya Sai Media Centre
              </a>
            </div>
          </div>
        </div>
      </header>
      {user !== false && <MapWithMarkers rdb={rdb} db={db} signIn={signIn} signOut={signOutUser} state={{ user }} />}
    </div>
  );
}