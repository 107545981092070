import React, { useEffect, useRef } from 'react';

const Birthday = () => {
  const canvasRef = useRef(null);
  const fireworksRef = useRef([]);
  const counterRef = useRef(0);

  const PI2 = Math.PI * 2;
  const random = (min, max) => Math.random() * (max - min + 1) + min | 0;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let width, height, spawnA, spawnB, spawnC, spawnD;

    const resize = () => {
      width = canvas.width = window.innerWidth;
      height = canvas.height = window.innerHeight;
      let center = width / 2 | 0;
      spawnA = center - center / 4 | 0;
      spawnB = center + center / 4 | 0;
      spawnC = height * 0.1;
      spawnD = height * 0.5;
    };

    const Firework = function(x, y, targetX, targetY, shade, offsprings) {
      this.dead = false;
      this.offsprings = offsprings;
      this.x = x;
      this.y = y;
      this.targetX = targetX;
      this.targetY = targetY;
      this.shade = random(0, 1) > 0.5 ? random(30, 60) : random(0, 30); // Gold or Silver
      this.brightness = random(50, 80);
      this.history = [];
    };

    Firework.prototype.update = function(delta) {
      if (this.dead) return;

      let xDiff = this.targetX - this.x;
      let yDiff = this.targetY - this.y;
      if (Math.abs(xDiff) > 3 || Math.abs(yDiff) > 3) {
        this.x += xDiff * 2 * delta;
        this.y += yDiff * 2 * delta;

        this.history.push({
          x: this.x,
          y: this.y
        });

        if (this.history.length > 20) this.history.shift();
      } else {
        if (this.offsprings && !this.madeChilds) {
          let babies = this.offsprings / 2;
          for (let i = 0; i < babies; i++) {
            let targetX = this.x + this.offsprings * Math.cos(PI2 * i / babies) | 0;
            let targetY = this.y + this.offsprings * Math.sin(PI2 * i / babies) | 0;
            fireworksRef.current.push(new Firework(this.x, this.y, targetX, targetY, this.shade, 0));
          }
        }
        this.madeChilds = true;
        this.history.shift();
      }

      if (this.history.length === 0) this.dead = true;
      else if (this.offsprings) {
        for (let i = 0; this.history.length > i; i++) {
          let point = this.history[i];
          ctx.beginPath();
          ctx.fillStyle = `hsl(${this.shade}, 100%, ${this.brightness}%)`;
          ctx.arc(point.x, point.y, 2, 0, PI2, false);
          ctx.fill();
        }
      } else {
        ctx.beginPath();
        ctx.fillStyle = `hsl(${this.shade}, 100%, ${this.brightness}%)`;
        ctx.arc(this.x, this.y, 2, 0, PI2, false);
        ctx.fill();
      }
    };

    const update = (delta) => {
      delta *= 0.5; // Slow down the animation
      ctx.clearRect(0, 0, width, height);
    
      ctx.globalCompositeOperation = 'lighter';
      for (let firework of fireworksRef.current) firework.update(delta);
    
      counterRef.current += delta * 2;
      if (counterRef.current >= 1) {
        fireworksRef.current.push(new Firework(
          random(spawnA, spawnB),
          height,
          random(0, width),
          random(spawnC, spawnD),
          random(0, 360),
          150
        ));
        counterRef.current = 0;
      }
    
      if (fireworksRef.current.length > 1000) fireworksRef.current = fireworksRef.current.filter(firework => !firework.dead);
    };
    

    let then = performance.now();

    const loop = () => {
      let now = performance.now();
      let delta = (now - then) / 1000;
      then = now;
      update(delta);
      requestAnimationFrame(loop);
    };

    resize();
    loop();

    window.addEventListener('resize', resize);
    canvas.addEventListener('click', e => {
      let x = e.clientX || e.touches && e.touches[0].pageX;
      let y = e.clientY || e.touches && e.touches[0].pageY;

      let count = random(3, 5);
      for (let i = 0; i < count; i++) fireworksRef.current.push(new Firework(
        random(spawnA, spawnB),
        height,
        x,
        y,
        random(0, 360),
        random(30, 110)
      ));

      counterRef.current = -1;
    });

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return <canvas ref={canvasRef} id="birthday" />;
};

export default Birthday;
