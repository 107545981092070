import React, { Component } from "react";
import axios from 'axios';
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapGL, {  GeolocateControl, Marker, NavigationControl, Popup } from "react-map-gl";
import { collection, query, where, onSnapshot, orderBy, doc, setDoc } from "firebase/firestore"; 
import { ref, set, get, child, increment, update, onValue } from "firebase/database";

    // added the following 6 lines.
import mapboxgl from 'mapbox-gl';

import DetectLanguage from "detectlanguage";

import translate from "translate";

import languages from "./languages.json";


translate.engine = "google";
const detectlanguage = new DetectLanguage('23e5b23f7b9a27a5f54655a576b0adda');
//const detectlanguage = new DetectLanguage('d4ab82f70f937db6179ffbc2f0c5dea6');

    // The following is required to stop "npm build" from transpiling mapbox code.
    // notice the exclamation point in the import.
    // @ts-ignore
    // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const col = "greeting";

function setDays (daysToAdd, date = new Date()) {
    var _24HoursInMilliseconds = 86400000;
    return new Date(date.getTime() + daysToAdd * _24HoursInMilliseconds);
  };


  function timeAgo(input) {
    const date = (input instanceof Date) ? input : new Date(input);
    const formatter = new Intl.RelativeTimeFormat('en');
    const ranges = {
      years: 3600 * 24 * 365,
      months: 3600 * 24 * 30,
      weeks: 3600 * 24 * 7,
      days: 3600 * 24,
      hours: 3600,
      minutes: 60,
      seconds: 1
    };
    const secondsElapsed = (date.getTime() - Date.now()) / 1000;
    for (let key in ranges) {
      if (ranges[key] < Math.abs(secondsElapsed)) {
        const delta = secondsElapsed / ranges[key];
        return formatter.format(Math.round(delta), key);
      }
    }
  }

  const toKMB = (labelValue) => {
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

    : Math.abs(Number(labelValue));
  }


  const realtime = {
    autoIncrease: async (rdb, fields, amt = 1) => {
      const updates = {};
      fields.forEach((x)=>{
        updates[x] = increment(amt);
      })
      return await update(ref(rdb, `/summary`), updates);
    },
    get: async (rdb, col = `/summary`, uid = '', qry = '') => {
      return await get(child(ref(rdb), `${col}/${uid}${uid === '' ? '' : qry}`));
    },
    set: async (rdb, col, data, id = false) => {
      set(ref(rdb, `${col}${id ? `/${id}` : ''}`), data);
    },
    ref: async(rdb, col = 'summary') => {
      return ref(rdb, `/${col}`);
    }
  }

class MapWithMarkers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewport: {
        width: '100%',
        height: `100vh`,
        latitude: 0, // Initial latitude
        longitude: 0, // Initial longitude
        zoom: 3, // Initial zoom level
        scrollZoom: true,
        dragPan: true,
        dragRotate: true,
        touchZoom: true,
        touchRotate: true,
        doubleClickZoom: true,
        keyboard: true
      },
      markers: [
      ],
      summary: {},
      selectedMarker: null,
      showform: false,
      position: [0,0],
      formObj: {
        name: '',
        msg: ''
      },
      location: '',
      zoom: 2,
      resp: '',
      mymsg: {},
      showComments: true,
      newmsg: true,
      textchanged: false,
      lang: {},
      translate: false,
      translating: false
    };
    this.mapRef = React.createRef();
  }

  IsValidText = async (text) => {
    const url = `https://api1.webpurify.com/services/rest/?api_key=49197cc323e592aa24cace6bfd24e538&method=webpurify.live.check&text=${text}&replacesymbol=%23&semail=1&sphone=1&slink=1&cdata=1&format=json`;
    let config = {
      method: 'get',
      url: url,
      headers: {'Content-Type': 'application/json'}
    };

    let resp;
    try{ 
      resp = await axios.request(config);
     // console.log(resp.data)
    }catch(ex){
     // console.log(ex)
    }
   
  }

  filterText = async (text) => {
    const url = `https://api.dreamzlive.com/purifytext/?text=${text}`;
    let config = {
      method: 'get',
      url: url,
      headers: { 'Content-Type': 'application/json', }
    };
    
    let resp;
    try{ 
      resp = await axios.request(config);
      return resp.data;
    }catch(ex){
      console.log(ex)
    }
  }
  
  setMapCenter = (lat, lng) => {
      const map = this.mapRef.current.getMap();
      
      const newCenter = { lng: lng, lat: lat };
      map.panTo(newCenter);
  }

  getLocationInfo = async (lat, lng) => {
    const url = `https://geocode.maps.co/reverse?lat=${lat}&lon=${lng}`
    let config = {
      method: 'get',
      url: url,
      headers: { 'Content-Type': 'application/json', }
    };
    
    let resp;
    try{ 
      resp = await axios.request(config);
      return resp.data;
    }catch(ex){
      //console.log(ex)
    } 
  }

  handleMarkerClick = async (marker) => {
    
    let viewport = this.state.viewport;
    viewport['latitude'] = marker.lat;
    viewport['longitude'] = marker.lng;
    
    this.setState({
      translate:false,
      translating: true
    }, async () => {
      
      this.setMapCenter(marker.lat, marker.lng);
      this.setState({ selectedMarker: marker, viewport: viewport, translating: false }, async ()=>{
        if(document.getElementById(`${marker.id}`) !== null){
          document.getElementById(`${marker.id}`).scrollIntoView({behavior: 'smooth'});
        }
        if( marker.lang !== undefined && marker.lang.code !== 'en'){
          marker.translate = await this.translate(marker.msg, marker.lang.code);
        }
        marker.location = await this.getLocationInfo(marker.lat, marker.lng);
       
       // console.log(marker);
        this.setState({
          selectedMarker: marker
        })
      });
    })
    
  };

  handleClosePopup = () => {
    if(this.state.selectedMarker !== null){

       this.setState({ selectedMarker: null });
    }
  };

  async componentDidMount(){
    
    
    const { innerWidth } = window;
    if(innerWidth < 800){
      this.setState({
        showComments: false
      })
    }
    await this.getGeoLocation();
    await this.getdata();
    let refr = await realtime.ref(this.props.rdb);
    onValue(refr, (snapshot) => {
        const data = snapshot.val();
        this.setState({
            summary: data
        })
    });
    
    
  }

  mapLoaded = (e) => {
    this.setMapCenter(this.state.position[0], this.state.position[1]);
  }

  

  getdata = async () => {
    let greetings = [];
    const q = query(collection(this.props.db, col), where("updatedOn", ">=", setDays(-60)), orderBy("updatedOn", "asc"));
    onSnapshot(q, (querySnapshot) => {
        
        querySnapshot.forEach((doc) => {
            let docdata = doc.data();
            if(docdata['updatedOn'] !== undefined){
                docdata['updatedOn'] = docdata['updatedOn'].toDate();
                docdata['timeAgo'] = timeAgo(docdata['updatedOn']);
            }
            if(Array.isArray(greetings)){
              const obj = {id:  doc.id, key: (greetings.length + 1), uid: doc.id, ...docdata};
              greetings = greetings.filter(x => x.uid  !== doc.id);
              greetings.push(obj);
            }
           
        });
        let mymsg = this.props.state.user === null ? {} : greetings.filter((x)=>{
            return  x.uid === this.props.state.user.uid
        });
        mymsg = mymsg[0] !== undefined ? mymsg[0] : {}
        let name;
        if(this.props.state.user !== null){
            name = this.props.state.user.isAnonymous ? '' : this.props.state.user.displayName;
        }
        

        this.setState({
            markers: greetings,
            mymsg: mymsg,
            newmsg: Object.keys(mymsg).length < 1,
            formObj: {
                name: mymsg.name !== undefined ? mymsg.name : name,
                msg: mymsg.msg
            }
        })
       
       
      });
  }

  getGeoLocation = async () => {
    let viewport, mypos;
    if ("geolocation" in navigator) {
      // Geolocation is available in the browser
      navigator.geolocation.getCurrentPosition((position)=>{
        viewport = {
            "width": 800,
            "height": 600,
            "latitude": position.coords.latitude,
            "longitude": position.coords.longitude,
            "zoom": 3
        }
        this.setState({
          location: "GEO",
          position: [ 
            position.coords.latitude,
            position.coords.longitude],
            viewport: viewport
        }, ()=>{
         // console.log(this.state)
        });
        mypos = [position.coords.latitude,position.coords.longitude]
      },async (error)=>{
        console.log(error);
        let pos = await this.getIpLocation();
        
        if(pos !== undefined){
            viewport = {
                "width": 800,
                "height": 600,
                "latitude": pos.latitude,
                "longitude": pos.longitude,
                "zoom": 3
            }
          this.setState({
            location: "IP",
            position: [pos.latitude,pos.longitude],
            viewport: viewport
          });
          mypos = [pos.latitude,pos.longitude]
        }
      });
      
    }
    else{
      let pos = await this.getIpLocation();
      
        if(pos !== undefined){
            viewport = {
                "width": '100%',
                "height": '100%',
                "latitude": pos.latitude,
                "longitude": pos.longitude,
                "zoom": 3
            }
          this.setState({
            location: "IP",
            position: [pos.latitude,pos.longitude],
            viewport: viewport
          }, ()=>{
            console.log(this.state)
          });
          mypos = [pos.latitude,pos.longitude]
        }
    }
    return mypos;
  }

  getIpLocation = async () => {
    let config = {
      method: 'get',
      url: 'https://api.geoapify.com/v1/ipinfo?&apiKey=09016f9db58243c48bdf5d7fd985c393',
      headers: { }
    };
    try{
      let resp = await axios.request(config);
      if(resp.data !== undefined && resp.data.location !== undefined){
  
        return (resp.data.location);
      }
    }catch(ex){
      console.log(ex);
    }
    
  }

  translate = async (text, from)=>{
     
      if(text !== undefined && from !== undefined){
        translate.from = from;
        return await translate(text, "en");
      }else{
        return text;
      }
      
  }

  submitComment = async (e) => {
    e.preventDefault();
    this.setState({
        resp: <div className="mt-2 alert alert-info">Processing...</div>
    });
    const {name,msg} = e.target.elements;
    
    if(this.props.state.user !== null){
        let pos = this.state.position;
        if(pos.includes(0)){
          pos = await this.getIpLocation();
          pos = [pos.latitude, pos.longitude];
         // console.log("IP", pos);
        }
        
        let data = {
            name: name.value,
            msg: msg.value,
            lat: pos[0],
            lng: pos[1],
            updatedOn: new Date(),
            lang: this.state.lang
        };
       
        const checknew = this.state.newmsg;
        await setDoc(doc(this.props.db, col, this.props.state.user.uid), data);
        
        if(checknew){
            await realtime.autoIncrease(this.props.rdb, ['greetings'],1);
        }
        
        const selectedMarker = {
            "id": this.props.state.user !== null ? this.props.state.user.uid : (this.state.markers.length + 1),
            "lat": this.state.position[0],
            "lng":this.state.position[1],
            "name": `Hi ${name.value}`,
            "msg": <>{msg.value}<br /><div className="text-end"></div></>,
            "uid": this.props.state.user !== null ? this.props.state.user.uid : '',
            resp: <div className="alert alert-success">Message has been submitted!</div>
        }
        this.setState({
            resp: '',
            selectedMarker: selectedMarker,
            showform: false,
            newmsg: false
        })
    }
    
  }

  handleViewportChange = (newViewport) => {
   
    this.setState({ viewport: newViewport });
  };

  changeMapCenter = (newLatitude, newLongitude) => {
    const newViewport = { ...this.state.viewport };
    newViewport.latitude = newLatitude;
    newViewport.longitude = newLongitude;
    this.handleViewportChange(newViewport);
  };

  displayAddr = (addr) => {
    if(typeof addr === `string`){
      addr = addr.split(",");
      if(addr.length > 6){
        addr.shift();
        addr.shift();
        addr.shift();
      }
      
      return addr.join(', ');
    }else{
      return addr;
    }
    
  }

  

  render() {
    const {user} = this.props.state;
    const {mymsg} = this.state;
   
    return (
     <>
      <div id="mapbox">
        <div className="map-overlay"></div>
        <div className="mapbox-map">
        {
            !this.state.position.includes(0) ?
            <ReactMapGL
            ref={this.mapRef}
            id="myMapbox"
            worldview={`IN`}
            
         onLoad={this.mapLoaded}
         
         onViewportChange={this.handleViewportChange}
         mapboxAccessToken="pk.eyJ1Ijoic3NzbWNkZXZlbG9wZXIiLCJhIjoiY2xvOW0zb283MGJwZjJzcGVqNGtuOHJnYyJ9.kgaxEjRzPNARrT0Fgf5IzQ"
         mapStyle="mapbox://styles/sssmcdeveloper/cm2ecy5hc00y001pehfuc248a"
     
       >
        {
            (mymsg.msg === undefined || mymsg.msg === '') &&
            <Marker
             key={user !== null ? user.uid : '0'}
             latitude={this.state.position[0]}
             longitude={this.state.position[1]}
           >
             <div
               className="marker"
               onClick={()=>{
                
                const selectedMarker = {
                    "id": 0,
                    "lat": this.state.position[0],
                    "lng":this.state.position[1],
                    "name": user !== null && user.displayName !== null ? `Hi ${user.displayName}` : "Hi there",
                    "msg": <>This is your location.<br /><div className="text-end"><button className="btn btn-primary btn-sm" onClick={()=>{
                        this.setState({
                            showform: true,
                            selectedMarker: null
                        })
                    }}>Share your greetings</button></div></>,
                    "uid": user !== null ? user.uid : ''
                }
                this.setState({
                    selectedMarker: selectedMarker
                })
               }}
             >
               {
                   user !== null && user.photoURL !== null ?
                   <div className="pin-img map-profile">
                   <img
                   src={user.photoURL}
                   style={{ maxWidth: "36px" }}
                   alt="Comment Pin"
                   />
                   </div> :
                   <div  className="pin-img text-danger" style={{fontSize:"36px"}}>
                   <i className="fa-solid fa-map-pin"></i>
                   </div>
               }
               
               
             </div>
             
           </Marker>
        }
         {this.state.markers.map((marker) => (
           <Marker
             key={marker.uid}
             latitude={marker.lat}
             longitude={marker.lng}
           >
             <div
               className="marker"
               onClick={(e) => {
                e.stopPropagation();
                this.handleMarkerClick(marker);
              }}
             >
                {
                   user !== null && marker.uid === user.uid && user.photoURL !== null ?
                   <div className="pin-img map-profile">
                   <img
                   src={user.photoURL}
                   style={{ maxWidth: "36px" }}
                   alt="Comment Pin"
                   />
                   </div> :
                   <>
                    {
                        this.state.selectedMarker !== null && this.state.selectedMarker.id === marker.id ?
                        <div className="pin-img">
                        <img style={{width:"36px", background:"#5D2D24", borderRadius:"50%"}} src="/icon-32x32.png" alt='' />
                        </div> :
                        <div  className="pin-img">
                        <img
                        src="/icon-32x32.png"
                        style={{ maxWidth: "36px" }}
                        alt="Comment Pin"
                        />
                        </div>
                    }
                   </>
               }
               
               
               
             </div>
             
           </Marker>
         ))}
         
         {this.state.selectedMarker !== null && (
           <>
           
           <Popup
           onClose={this.handleClosePopup}
           latitude={this.state.selectedMarker.lat}
           longitude={this.state.selectedMarker.lng}
           closeButton={true} closeOnClick={true}
         >
           <div className="popup-map-box">
             <h6 className="text-theme m-0">{this.state.selectedMarker.name}</h6>
             {
              this.state.translate && this.state.selectedMarker.translate !== undefined ?
              <div>{this.state.translating ? `Translating...` : (this.state.selectedMarker.translate)}</div>
              :<div>{(this.state.selectedMarker.msg)}</div>

             }
             
             <small className="text-muted">Posted {this.state.selectedMarker.timeAgo}</small>
             {
              this.state.selectedMarker.location !== undefined && this.state.selectedMarker.location.display_name !== undefined &&
              <small>&nbsp;<strong>from</strong> <span className="text-muted">{this.displayAddr(this.state.selectedMarker.location.display_name)}</span>&nbsp;</small>
             }
             {
              this.state.selectedMarker.lang !== undefined && this.state.selectedMarker.lang.code !== 'en' &&
              <>
              <small className="text-primary" onClick={()=>{
              this.setState({
                translate: !this.state.translate
              })
            }} style={{cursor:"pointer"}}>{this.state.translate ? <> Original</> : <> Translate <span className="text-muted">({this.state.selectedMarker.lang.name})</span></> } </small> 
              </>
            }
           </div>
         </Popup>
           </>
         )}
         
         <GeolocateControl position='bottom-left' />
         <NavigationControl style={{bottom:"20px"}} showCompass showZoom position='bottom-left' />
       </ReactMapGL> :
        <div style={{display:"flex", height:"100vh", width:"100vw", flexDirection:"column", textAlign:"center", justifyContent:"flex-end", alignItems:"center"}}>
            <img src="/icon-192x192.png" alt="" />
            <div className="text-white pb-5">Loading...</div>
        </div>
        }
        
       {
           this.state.selectedMarker !== null && 
           <div className="popup-box d-none">
            <div className="popup-close">
            <button onClick={this.handleClosePopup} className={`btn btn-light`}><i className="fa-solid fa-circle-xmark"></i></button>
                
            </div>
               <div className="popup-content">
               
               <h6 className="heading">{this.state.selectedMarker.name}</h6>
               <div className="text">{this.state.selectedMarker.msg}</div>
               {
                this.state.selectedMarker.resp !== undefined &&
                <>{this.state.selectedMarker.resp}</>
               }
               </div>
           </div>
       }
       <div className="comment-box">
            
            <div className="countdown-box">
            {
              this.state.summary !== null &&
            <div className="countdown-heading d-flex mb-0 ">
                <div style={!this.state.showform && !this.state.showComments ? {textAlign:"center",flex:"1"}: {}}>
                
                    <span className="badge text-muted bg-white-alpha">
                    {this.state.summary.greetings !== undefined && <strong className="badge bg-theme text-bold counter"><span className="display-6">{toKMB(this.state.summary.greetings)}</span></strong>} 
                    </span>
                
                </div>
                <div className={`${!this.state.showform && !this.state.showComments ? ' d-none': ''}`}>
                <button onClick={()=>{
                    this.setState({
                        showform: false,
                        showComments: false
                    })
                }} className={`btn btn-light`}><i className="fa-solid fa-circle-xmark"></i></button>
                </div>
            </div>}
            {
                this.state.showComments && 
                <div className="card">
                    <div className="card-body">
                    <div className={this.state.selectedMarker === null ? "msgbox" : "msgbox opened"}>
                        <ul className="list-group">
                            {
                                this.state.markers.map((x,i)=>{
                                    return <li id={x.uid} key={i} className="list-group-item">
                                        <div onClick={(e) => {
                                          e.stopPropagation();
                                          this.handleMarkerClick(x);
                                        }} className={this.state.selectedMarker !== null && this.state.selectedMarker.uid === x.uid ? "alert-bubble selected" : "alert-bubble"} style={{cursor:"pointer"}}><span className="badge text-bubble">{x.name}:</span> <span className="msg">{x.msg}</span></div>
                                        <div className="text-start time"><span className="text-secondary p-0">Posted {x.timeAgo}</span></div>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    </div>
                </div>
            }

{
                this.state.showform &&
                <div className="comment-content">
                {
                    user === null ? 
                     <>
                     <h5 className="m-0 text-center d-none">Sign In</h5>
                     <div className="signin-btn d-flex" style={{gap:"10px", justifyContent:"space-around", flexWrap:"wrap"}}>
                            
                            <div className="form-check checkbox-xl">
                                <input type="checkbox" className="form-check-input" id="anonymous" onChange={(e)=>{
                                    e.preventDefault();
                                    e.target.disabled = true;
                                    this.props.signIn('anonymous');
                                }} />
                                <label className="form-check-label" htmlFor="anonymous"><strong>Solve reCAPTCHA, </strong>I&apos;m not a robot!</label>
                            </div>
                           
                    </div>
                     </> :
                    <form onSubmit={this.submitComment}>
                    <div className="userinfo">
                        <div className="row">
                            <div className="col-12 text-center">
                               
                            <div className="d-flex" style={{alignItems:"center", justifyContent:"flex-end"}}>
                                <div>
                                {
                                    user.photoURL !== null &&
                                    <div className="profile">
                                        <img src={user.photoURL} alt="" />
                                    </div>
                                }
                                </div>
                                <div className="d-none">
                                    <button type="button" className="btn btn-default btn-sm" title="Sign out" onClick={()=>{
                                    this.props.signOut()
                                }}><i className="fa-solid fa-right-from-bracket"></i> Sign out</button>
                                </div>
                            </div>
                                
                            <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <input type="hidden" name="isnew" id="isnew" value={this.state.newmsg} />
                        <label htmlFor="name">Your Name</label>
                        <input type="name" id="name" className="form-control" value={this.state.formObj.name} placeholder="Your Display Name" required maxLength={40} onChange={(e)=>{
                            let frm = this.state.formObj;
                            frm['name'] = e.target.value;
                            this.setState({
                                formObj:frm
                            })
                        }}  />
                    </div>
                    
                    
                    <div className="form-group">
                        <label htmlFor="msg">Your Message</label>
                        <textarea type="msg" onBlur={async (e)=>{
                              let msg = e.target.value.trim();
                              if(this.state.textchanged && msg !== ''){
                                this.setState({
                                  resp: "Checking..."
                                });
                                let text = await this.filterText(e.target.value);
                                let frm = this.state.formObj;
                                frm['msg'] = text !== undefined ? text.filtered : msg;
                                let lang;
        
                                try{
                                  lang = await detectlanguage.detect(frm['msg']);
                                  lang = lang[0] !== undefined ? lang[0] : lang;
                                  //console.log(lang);
                                  if(lang !== undefined){
                                    let nl = languages.filter(i => i.code === lang.language);
                                    lang = nl[0] !== undefined ? nl[0] : {};
                                  }
                                }catch(ex){
                                  console.log(ex);
                                  lang = {}
                                }
                                
                               
                                
                                this.setState({
                                    formObj: frm,
                                    resp: '',
                                    lang: lang,
                                    textchanged: false
                                }, ()=>{
                                //  console.log(this.state);
                                })
                              }

                            }} id="msg" className="form-control" value={this.state.formObj.msg !== null ? this.state.formObj.msg : ""} placeholder="Your Message" required maxLength={'140'} onChange={(e)=>{
                            let frm = this.state.formObj;
                            frm['msg'] = e.target.value;
                            this.setState({
                                formObj:frm,
                                textchanged: true
                            })
                        }} />
                        
                        {
                            this.state.formObj.msg !== undefined &&
                            <div className="row">
                              <div className="col-6" onClick={()=>{
                                
                                
                              }}>
                                {
                                  this.state.lang !== undefined && this.state.lang.name !== undefined &&
                                  <small className="d-none">{this.state.lang.nativeName} ({this.state.lang.name})</small>
                                }
                              </div>
                              <div className="col-6 text-end">
                                {
                                  this.state.formObj.msg !== null && 
                                  <small className="text-muted">({this.state.formObj.msg.length}/140)</small>
                                }
                                  
                              </div>
                            </div>
                        }
                        
                    </div>
                    <div className="p-1">
                        {
                            this.state.resp
                        }
                    </div>
                    {
                        this.state.resp === '' &&
                        <div className="form-group">
                        {  user.isAnonymous ?
                                <button type="submit" className="btn btn-primary">Submit</button>:
                                <button type="submit" className="btn btn-primary">Submit</button>
                            }
                        
                            
                        </div>
                    }
                    </form>
                }
                
                </div>
               }
            <div className="p-1"></div>
            
            <div className="text-center d-flex" style={{gap:"10px", justifyContent:"center"}}>
            
            <button onClick={async ()=>{
                await this.getGeoLocation();
                this.setState({
                    showform:!this.state.showform,
                    showComments: false
                })
            }} className={`btn btn-md btn-theme`}>Send My Greetings</button>

            <button onClick={()=>{
                this.setState({
                    showform:false,
                    showComments: !this.state.showComments
                })
            }} className={`btn btn-md btn-warning`}>{this.state.showComments ? 'Hide Greetings' : 'Show Greetings'}</button>
            </div>
            <div className="p-1"></div>
            </div>
           
           
               
           
       </div>
       
        </div>
      </div>
      
      
     </>
    );
  }
}

export default MapWithMarkers;
